import {useEffect} from "react";
import {useAuth} from "./AuthContext";
import {useLocation} from "react-router-dom";
import {SYSTEM_NUMBER} from "../util/Constants";

const AuthCheck = () => {
    const location = useLocation();
    const { token, logout } = useAuth();

    useEffect(() => {
        if (token) {
            const currentTime = Date.now() / SYSTEM_NUMBER.ONE_SECOND_MS;
            if (token.exp < currentTime) {
                logout();
            }
        }
    }, [location]);

    return null;
};

export default AuthCheck;