import React from 'react';
import { useLoading } from './LoadingContext';

const LoadingSpinner = () => {
    const { isLoading } = useLoading();

    if (!isLoading)
        return null;

    return (
        <div className="loading-overlay">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    );
};

export default LoadingSpinner;
