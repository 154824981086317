import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {renderNavbar, renderNavbarDropdown} from "../utils/util/RenderUtil";
import {useAuth} from "../utils/auth/AuthContext";
import {useAlert} from "../utils/alert/AlertContext";
import {ALERT_TYPE, ALERT_MESSAGE} from "../utils/util/Constants";
import {useLoading} from "../utils/loading/LoadingContext";
import {useFetchWithToken} from "../utils/util/FetchUtils";

const Navbar = () => {
    const { token, logout } = useAuth();
    const { triggerAlert } = useAlert();
    const { setIsLoading } = useLoading();
    const fetchWithToken = useFetchWithToken();

    const handleLogout = async () => {
        try {
            setIsLoading(true);

            const response = await fetchWithToken('/api/auth/logout', {
                method: "POST",
            });

            if (response.ok) {
                logout();

                triggerAlert(ALERT_MESSAGE.LOGOUT_SUCCESS, ALERT_TYPE.SUCCESS);
            } else {
                triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
            }
        } catch (error) {
            triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
        } finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        renderNavbar();
        renderNavbarDropdown();

        return () => {
            document.documentElement.style.overflow = '';
            document.body.style.paddingRight = '';
        };
    }, []);

    return (
        <nav className="navbar navbar-expand-lg bg-white border-bottom">
            <div className="container">
                {/* logo按键 */}
                <Link className="navbar-brand" to="/">
                    <img src={`${process.env.PUBLIC_URL}/img/logo_full.jpg`} className="navbar-brand-img" alt="..."/>
                </Link>

                {/* 折叠菜单展开按钮 */}
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false"
                        aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>

                {/* 折叠菜单组件 */}
                <div className="collapse navbar-collapse" id="navbarCollapse">

                    {/* 折叠菜单关闭按钮 */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                            data-bs-target="#navbarCollapse" aria-controls="navbarCollapse"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <i className="fe fe-x"></i>
                    </button>

                    {/* 导航列表 */}
                    <ul className="navbar-nav ms-auto">
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" id="navbarBlog" data-bs-toggle="dropdown"
                                  aria-haspopup="true" aria-expanded="false">
                                博客
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarBlog">
                                <li className="dropdown-item">
                                    <Link className="dropdown-item" to="/blogs">
                                        浏览
                                    </Link>
                                    <Link className="dropdown-item" to="/blogs/new">
                                        写作
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item dropdown">
                            <Link className="nav-link dropdown-toggle" id="navbarAxis" data-bs-toggle="dropdown"
                                  aria-haspopup="true" aria-expanded="false">
                                时间轴
                            </Link>
                            <ul className="dropdown-menu" aria-labelledby="navbarAxis">
                                <li className="dropdown-item">
                                    <Link className="dropdown-item">
                                        待开发
                                    </Link>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    {/* 登录/注销按钮 */}
                    {token ?
                        <button type='button' className="navbar-btn btn btn-sm btn-primary lift ms-auto"
                                onClick={() => handleLogout()}>
                            注销
                        </button>
                        :
                        <Link className="navbar-btn btn btn-sm btn-primary lift ms-auto" to="/login">
                            登录
                        </Link>
                    }
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
