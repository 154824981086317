import React from 'react';
import Navbar from '../../component/Navbar';
import Footer from '../../component/Footer'
import Breadcrumb from "../../component/Breadcrumb";
import {useLocation} from "react-router-dom";

const MainLayout = ({ children }) => {
    const location = useLocation();
    const isMainPage = location.pathname === '/';

    return (
        <div className={"bg-light"}>
            <Navbar/>
            <div>
                {children}
            </div>
            <Footer/>
        </div>
    );
};

export default MainLayout;
