import React, {createContext, useContext, useEffect, useState} from 'react';
import {jwtDecode} from "jwt-decode";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [isTokenLoading, setIsTokenLoading] = useState(true);

    const refreshToken = (token) => {
        setToken(jwtDecode(token));
        localStorage.setItem('token', token);
    };

    const removeToken = () => {
        setToken(null);
        localStorage.removeItem('token');
    };

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setToken(jwtDecode(token));
        }

        setIsTokenLoading(false);
    }, []);

    if (isTokenLoading) {
        return null;
    }

    return (
        <AuthContext.Provider value={{ token: token, login: refreshToken, logout: removeToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => useContext(AuthContext);
