import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import BlogList from "./page/blog/BlogList";
import BlogPost from "./page/blog/BlogPost";
import BlogContent from "./page/blog/BlogContent";
import MainLayout from "./utils/layout/MainLayout";
import SimpleLayout from "./utils/layout/SimpleLayout";
import Login from "./page/account/Login";
import Register from "./page/account/Register";
import ScrollToTop from "./utils/top/ScrollToTop";
import {LoadingProvider} from "./utils/loading/LoadingContext";
import LoadingSpinner from "./utils/loading/LoadingSpinner";
import {AuthProvider} from "./utils/auth/AuthContext";
import AuthCheck from "./utils/auth/AuthCheck";
import PrivateRoute from "./utils/auth/PrivateRoute";
import {AlertProvider} from "./utils/alert/AlertContext";
import AlertComponent from "./utils/alert/AlertComponent";

function App() {
    return (
        <BrowserRouter>
            <AuthProvider>
                <AuthCheck/>
                <LoadingProvider>
                    <LoadingSpinner/>
                    <AlertProvider>
                        <AlertComponent/>
                        <ScrollToTop/>
                        <Routes>
                            <Route path="/" element={
                                <Navigate to="/blogs"/>
                            }/>
                            <Route path="/blogs" element={
                                <MainLayout>
                                    <BlogList/>
                                </MainLayout>
                            }/>
                            <Route path="/blogs/new" element={
                                <PrivateRoute>
                                    <MainLayout>
                                        <BlogPost/>
                                    </MainLayout>
                                </PrivateRoute>
                            }/>
                            <Route path="/blogs/:blogId" element={
                                <MainLayout>
                                    <BlogContent/>
                                </MainLayout>
                            }/>
                            <Route path="/login" element={
                                <SimpleLayout>
                                    <Login/>
                                </SimpleLayout>
                            }/>
                            <Route path="/resigter" element={
                                <SimpleLayout>
                                    <Register/>
                                </SimpleLayout>
                            }/>
                        </Routes>
                    </AlertProvider>
                </LoadingProvider>
            </AuthProvider>
        </BrowserRouter>
    );
}

export default App;
