import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {useLoading} from "../../utils/loading/LoadingContext";
import {ALERT_TYPE, ALERT_MESSAGE} from "../../utils/util/Constants";
import {useAlert} from "../../utils/alert/AlertContext";
import {useFetchWithToken} from "../../utils/util/FetchUtils";

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [keyword, setKeyword] = useState('');
    const { setIsLoading } = useLoading();
    const { triggerAlert } = useAlert();
    const fetchWithToken = useFetchWithToken();

    const fetchBlogs = async () => {
        try {
            setIsLoading(true);

            const response = await fetchWithToken(`/api/blogs?keyword=${encodeURIComponent(keyword)}`, {
                method: "GET",
            });
            if (response.ok) {
                const data = await response.json();
                setBlogs(data);
            } else {
                triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
            }
        } catch (error) {
            triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogs();
    }, []);

    return (
        <div>
            <section className="py-6">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <form className="rounded shadow">
                                <div className="input-group input-group-lg">
                                    <span className="input-group-text border-0 pe-1">
                                        <i className="fe fe-search"></i>
                                    </span>
                                    <input type="text" className="form-control border-0 px-1"
                                           aria-label="Search our blog..." placeholder="搜索博客"
                                           value={keyword} onChange={(e) => setKeyword(e.target.value)}/>
                                    <span className="input-group-text border-0 py-0 ps-1 pe-3">
                                        <span className="h6 text-uppercase text-body-secondary d-none d-md-block mb-0 me-5">
                                            {blogs.length} 篇博客
                                        </span>
                                        <button type="button" className="btn btn-sm btn-primary"
                                                onClick={() => fetchBlogs()}>
                                            搜索
                                        </button>
                                    </span>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container pb-8 pb-md-11 border-gray-300">
                    <div className="row">
                        <div className="col-12">
                            <div className="table-responsive mb-7 mb-md-9">
                                <table className="table table-align-middle">
                                    <thead>
                                    <tr>
                                        <th>
                                            <span className="h6 text-uppercase fw-bold">
                                                博客
                                            </span>
                                        </th>
                                        <th>
                                            <span className="h6 text-uppercase fw-bold">
                                                作者
                                            </span>
                                        </th>
                                        <th>
                                            <span className="h6 text-uppercase fw-bold">
                                                更新时间
                                            </span>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {
                                        blogs.map((blog) => (
                                            <tr key={blog.blogId}>
                                                <td>
                                                    <Link to={`/blogs/${blog.blogId}`}
                                                          className="text-reset text-decoration-none">
                                                        <p className="mb-1">
                                                            {blog.title}
                                                        </p>
                                                        <p className="fs-sm text-body-secondary mb-0" dangerouslySetInnerHTML={{ __html: blog.description }} />
                                                    </Link>
                                                </td>
                                                <td>
                                                    <Link to={`/users/${blog.user.userId}/blogs`}
                                                          className="text-reset text-decoration-none">
                                                        <p className="fs-sm mb-0">
                                                            {blog.user.username}
                                                        </p>
                                                    </Link>
                                                </td>
                                                <td>
                                                    <p className="fs-sm mb-0">
                                                        {blog.updateTime}
                                                    </p>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default BlogList;