export function renderNavbar () {
    const navbarTogglable = document.querySelectorAll('.navbar-togglable');
    const navbarCollapse = document.querySelectorAll('.navbar-collapse');
    const windowEvents = ['load', 'scroll'];

    let isLight = false;

    function makeNavbarDark(navbar) {
        navbar.classList.remove('navbar-light');
        navbar.classList.remove('bg-white');
        navbar.classList.add('navbar-dark');

        isLight = false;
    }

    function makeNavbarLight(navbar) {
        navbar.classList.remove('navbar-dark');
        navbar.classList.add('navbar-light');
        navbar.classList.add('bg-white');

        isLight = true;
    }

    function toggleNavbar(navbar) {
        const scrollTop = window.pageYOffset;

        if (scrollTop && !isLight) {
            makeNavbarLight(navbar);
        }

        if (!scrollTop) {
            makeNavbarDark(navbar);
        }
    }

    function overflowHide() {
        const scrollbarWidth = getScrollbarWidth();

        document.documentElement.style.overflow = 'hidden';
        document.body.style.paddingRight = scrollbarWidth + 'px';
    }

    function overflowShow() {
        document.documentElement.style.overflow = '';
        document.body.style.paddingRight = '';
    }

    function getScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    navbarTogglable.forEach(function(navbar) {
        windowEvents.forEach(function(event) {
            window.addEventListener(event, function() {
                toggleNavbar(navbar);
            });
        });
    });

    navbarCollapse.forEach(function(collapse) {
        collapse.addEventListener('show.bs.collapse', function() {
            overflowHide();
        });

        collapse.addEventListener('hidden.bs.collapse', function() {
            overflowShow();
        });
    });
}

export function renderNavbarDropdown() {
    const drops = document.querySelectorAll('.navbar-nav .dropdown, .navbar-nav .dropend');

    const showEvents = ['mouseenter', 'focusin'];
    const hideEvents = ['mouseleave', 'click', 'focusout'];

    const transitionDuration = 200;

    const desktopSize = 992;

    function showDrop(menu) {
        if (window.innerWidth < desktopSize) {
            return;
        }

        menu.classList.add('showing');

        setTimeout(function () {
            menu.classList.remove('showing');
            menu.classList.add('show');
        }, 1);
    }

    function hideDrop(e, menu) {
        setTimeout(function () {
            if (window.innerWidth < desktopSize) {
                return;
            }

            if (!menu.classList.contains('show')) {
                return;
            }

            if (e.type === 'click' && e.target.closest('.dropdown-menu form')) {
                return;
            }

            menu.classList.add('showing');
            menu.classList.remove('show');

            setTimeout(function () {
                menu.classList.remove('showing');
            }, transitionDuration);
        }, 2);
    }

    drops.forEach(function (dropdown) {
        const menu = dropdown.querySelector('.dropdown-menu');

        showEvents.forEach(function (event) {
            dropdown.addEventListener(event, function () {
                showDrop(menu);
            });
        });

        hideEvents.forEach(function (event) {
            dropdown.addEventListener(event, function (e) {
                hideDrop(e, menu);
            });
        });
    });
}