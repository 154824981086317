import {useAuth} from "../auth/AuthContext";

export const useFetchWithToken = () => {
    const {login} = useAuth();

    return async (url, options = {}) => {
        const token = localStorage.getItem('token');

        const defaultHeaders = {
            'Content-Type': 'application/json',
        };

        if (token) {
            defaultHeaders['Authorization'] = 'Bearer ' + token;
        }

        const headers = {
            ...defaultHeaders,
            ...options.headers
        };

        const fetchOptions = {
            ...options,
            headers
        };

        const response = await fetch(url, fetchOptions);

        const newAuthHeader = response.headers.get('Authorization');
        if (newAuthHeader) {
            const newToken = newAuthHeader.replace('Bearer ', '');
            login(newToken);
        }

        return response;
    };
};