import {Link, useNavigate} from "react-router-dom";
import {useState} from "react";
import {ALERT_TYPE, ALERT_MESSAGE, SYSTEM_NUMBER} from "../../utils/util/Constants";
import {useLoading} from "../../utils/loading/LoadingContext";
import {useAlert} from "../../utils/alert/AlertContext";

const Register = () => {
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [verificationCodeError, setVerificationCodeError] = useState('');
    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState('');
    const navigate = useNavigate();
    const { setIsLoading } = useLoading();
    const { triggerAlert } = useAlert();

    const validateEmail = () => {
        if (!email) {
            setEmailError('Email不能为空');
            return false;
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            setEmailError('请输入有效的email');
            return false;
        }
        setEmailError('');
        return true;
    };

    const handleVerificationCodeChange = (e) => {
        const inputValue = e.target.value;

        if (/^\d*$/.test(inputValue)) {
            setVerificationCode(inputValue);
        }
    }

    const validateVerificationCode = () => {
        if (!verificationCode) {
            setVerificationCodeError('验证码不能为空');
            return false;
        }
        setVerificationCodeError('');
        return true;
    }

    const validateUsername = () => {
        if (!username) {
            setUsernameError('用户名不能为空');
            return false;
        }
        setUsernameError('');
        return true;
    };

    const validatePassword = () => {
        if (!password) {
            setPasswordError('密码不能为空');
            return false;
        } else if (password.length < 6) {
            setPasswordError('密码至少6位');
            return false;
        }
        setPasswordError('');
        return true;
    };

    const validateConfirmPassword = () => {
        if (confirmPassword !== password) {
            setConfirmPasswordError('两次密码输入不一致');
            return false;
        }
        setConfirmPasswordError('');
        return true;
    };

    const sendEmailVerificationCode = async () => {
        try {
            setIsLoading(true);

            const verificationData = {
                email: email,
            }

            const response = await fetch('/api/auth/registration/email-verification-code', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(verificationData),
            });

            if (response.ok) {
                triggerAlert(ALERT_MESSAGE.VERIFICATION_CODE_SENT, ALERT_TYPE.INFO);
                startCountdown();
            } else {
                triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
            }
        } catch (error) {
            triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
        } finally {
            setIsLoading(false);
        }
    }

    const handleRegister = async () => {
        try {
            setIsLoading(true);

            const registerData = {
                email: email,
                verificationCode: verificationCode,
                username: username,
                password: password,
                confirmPassword: confirmPassword,
            };

            const response = await fetch('/api/auth/register', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(registerData),
            });

            if (response.ok) {
                navigate('/login');
            } else {
                triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
            }
        } catch (error) {
            triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
        } finally {
            setIsLoading(false);
        }
    }

    const startCountdown = () => {
        setIsButtonDisabled(true);
        let timeLeft = SYSTEM_NUMBER.VERIFICATION_CODE_GAP_SECOND;

        const intervalId = setInterval(() => {
            timeLeft -= 1;
            setCountdown(timeLeft);

            if (timeLeft === 0) {
                clearInterval(intervalId);
                setIsButtonDisabled(false);
            }
        }, SYSTEM_NUMBER.ONE_SECOND_MS);
    };

    return (
        <section>
            <div className="container d-flex flex-column">
                <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                    <div className="col-12 col-md-6 col-lg-4 py-8 py-md-11 ">
                        <h1 className="mb-0 fw-bold">
                            注册
                        </h1>
                        <p className="mb-6 text-body-secondary">
                            月圆之日.
                        </p>
                        <form className="mb-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="email">
                                    Email
                                </label>
                                <input type="email" maxLength={SYSTEM_NUMBER.EMAIL_MAX_LENGTH} className="form-control" id="email" placeholder="Email"
                                       value={email} onChange={(e) => setEmail(e.target.value)}
                                       onBlur={validateEmail}/>
                                {emailError && <p className="text-danger">{emailError}</p>}
                                <button type="button" className="btn btn-outline-primary btn-sm mt-3"
                                        onClick={() => sendEmailVerificationCode()} disabled={isButtonDisabled}>
                                    发送验证码{isButtonDisabled ? `(${countdown}s)` : ""}
                                </button>
                            </div>
                            <div className="form-group mb-5">
                                <label className="form-label" htmlFor="verificationCode">
                                    验证码
                                </label>
                                <input type="text" maxLength={SYSTEM_NUMBER.VERIFICATION_CODE_MAX_LENGTH} className="form-control" id="verificationCode" placeholder="Verification Code"
                                       value={verificationCode} onChange={handleVerificationCodeChange}
                                       onBlur={validateVerificationCode}/>
                                {verificationCodeError && <p className="text-danger">{verificationCodeError}</p>}
                            </div>
                            <div className="form-group mb-5">
                                <label className="form-label" htmlFor="username">
                                    用户名
                                </label>
                                <input type="text" maxLength={SYSTEM_NUMBER.USER_NAME_MAX_LENGTH} className="form-control" id="username" placeholder="Username"
                                       value={username} onChange={(e) => setUsername(e.target.value)}
                                       onBlur={validateUsername}/>
                                {usernameError && <p className="text-danger">{usernameError}</p>}
                            </div>
                            <div className="form-group mb-5">
                                <label className="form-label" htmlFor="password">
                                    密码
                                </label>
                                <input type="password" maxLength={SYSTEM_NUMBER.PASSWORD_MAX_LENGTH} className="form-control" id="password" placeholder="Password"
                                       value={password} onChange={(e) => setPassword(e.target.value)}
                                       onBlur={validatePassword}/>
                                {passwordError && <p className="text-danger">{passwordError}</p>}
                            </div>
                            <div className="form-group mb-5">
                                <label className="form-label" htmlFor="confirmPassword">
                                    确认密码
                                </label>
                                <input type="password" maxLength={SYSTEM_NUMBER.PASSWORD_MAX_LENGTH} className="form-control" id="confirmPassword" placeholder="Confirm Password"
                                       value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)}
                                       onBlur={validateConfirmPassword}/>
                                {confirmPasswordError && <p className="text-danger">{confirmPasswordError}</p>}
                            </div>
                            <button type="button" className="btn w-100 btn-primary" onClick={() => handleRegister()}>
                                注册
                            </button>
                        </form>
                        <p className="mb-0 fs-sm text-body-secondary">
                            已有账户？ <Link to="/login">登录</Link>.<br/><Link to="/">返回</Link>
                        </p>
                    </div>
                    <div className="col-lg-7 offset-lg-1 align-self-stretch d-none d-lg-block">
                        <div className="h-100 w-cover bg-cover"
                             style={{backgroundImage: `url(${process.env.PUBLIC_URL}/img/background_resigter.jpg)`}}></div>
                        <div className="shape shape-start shape-fluid-y text-white">
                            <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h100v386l-50 772v386H0V0z" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Register;