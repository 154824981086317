import React, {useEffect} from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import {useAlert} from "../alert/AlertContext";
import {ALERT_TYPE, ALERT_MESSAGE} from "../util/Constants";

const PrivateRoute = ({ children }) => {
    const { token } = useAuth();
    const { triggerAlert } = useAlert();

    useEffect(() => {
        if (!token) {
            triggerAlert(ALERT_MESSAGE.LOGIN_FIRST, ALERT_TYPE.PRIMARY);
        }
    }, [token]);

    return token ? children : <Navigate to="/login"/>;
};

export default PrivateRoute;
