import {useNavigate} from "react-router-dom";
import React, {useState} from 'react';
import QuillEditor from "../../component/QuillEditor";
import {ALERT_TYPE, ALERT_MESSAGE} from "../../utils/util/Constants";
import {useLoading} from "../../utils/loading/LoadingContext";
import {useAuth} from "../../utils/auth/AuthContext";
import {useAlert} from "../../utils/alert/AlertContext";
import {useFetchWithToken} from "../../utils/util/FetchUtils";

const BlogPost = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const navigate = useNavigate();
    const { setIsLoading } = useLoading();
    const { token } = useAuth();
    const { triggerAlert } = useAlert();
    const fetchWithToken = useFetchWithToken();

    const quillOptions = {
        placeholder: "Content",
    };

    const handleSave = async () => {
        try {
            setIsLoading(true);

            const blogData = {
                title: title,
                content: content,
                userId: token.sub,
            };

            const response = await fetchWithToken('/api/blogs', {
                method: 'POST',
                body: JSON.stringify(blogData),
            });

            if (response.ok) {
                triggerAlert(ALERT_MESSAGE.BLOG_POST_SUCCESS, ALERT_TYPE.SUCCESS);

                navigate('/blogs');
            } else {
                triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
            }
        } catch (error) {
            triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <main className="pb-8 pb-md-11 py-6">
            <div className="container-md">
                <div className="row">
                    <div className="col-12">
                        <div className="card card-bleed shadow-light-lg mb-6">
                            <div className="card-header">
                                <h4 className="mb-0">
                                    博客写作
                                </h4>
                            </div>
                            <div className="card-body">
                                <form>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">标题</label>
                                                <input className="form-control" id="name" type="text" placeholder="Title"
                                                       value={title} onChange={(e) => setTitle(e.target.value)}/>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="form-label">内容</label>
                                                <QuillEditor options={quillOptions} onContentChange={setContent}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-md-auto">
                                            <button type="button" className="btn w-100 btn-primary" onClick={() => handleSave()}>保存</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

export default BlogPost;