import React, {useEffect} from 'react';
import {useAlert} from "./AlertContext";
import {SYSTEM_NUMBER} from "../util/Constants";

const AlertComponent = () => {
    const { showAlert, alertConfig, closeAlert } = useAlert();

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                closeAlert();
            }, SYSTEM_NUMBER.ALERT_DURATION_MS);

            return () => clearTimeout(timer);
        }
    }, [showAlert, alertConfig]);

    return (
        <div className={`alert-container ${showAlert ? 'show' : 'hide'}`}>
            <div className={`alert alert-${alertConfig.type}`} role="alert">
                {alertConfig.message}
            </div>
        </div>
    );
};

export default AlertComponent;