import React, { createContext, useState, useContext } from 'react';
import {ALERT_TYPE} from "../util/Constants";

const AlertContext = createContext();

export const useAlert = () => {
    return useContext(AlertContext);
};

export const AlertProvider = ({ children }) => {
    const [showAlert, setShowAlert] = useState(false);
    const [alertConfig, setAlertConfig] = useState({
        message: '',
        type: ''
    });

    const triggerAlert = (message, type = ALERT_TYPE.INFO) => {
        setAlertConfig({ message, type });
        setShowAlert(true);
    };

    const closeAlert = () => {
        setShowAlert(false);
    };

    return (
        <AlertContext.Provider value={{ showAlert, alertConfig, triggerAlert, closeAlert }}>
            {children}
        </AlertContext.Provider>
    );
};
