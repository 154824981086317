import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {useLoading} from "../../utils/loading/LoadingContext";
import {ALERT_TYPE, ALERT_MESSAGE} from "../../utils/util/Constants";
import {useAlert} from "../../utils/alert/AlertContext";
import {useFetchWithToken} from "../../utils/util/FetchUtils";

const BlogContent = () => {
    const { blogId } = useParams();
    const [blog, setBlog] = useState(null);
    const { setIsLoading } = useLoading();
    const { triggerAlert } = useAlert();
    const fetchWithToken = useFetchWithToken();

    const fetchBlogContent = async () => {
        try {
            setIsLoading(true);

            const response = await fetchWithToken(`/api/blogs/${encodeURIComponent(blogId)}`, {
                method: "GET",
            });
            if (response.ok) {
                const data = await response.json();
                setBlog(data);
            } else {
                triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
            }
        } catch (error) {
            triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchBlogContent();
    }, []);

    return (
        <section className="pt-8 pt-md-11 pb-8 pb-md-14">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-12 col-md">
                        <h1 className="display-4 mb-2">
                            {blog ? blog.title : ''}
                        </h1>
                        <p className="fs-lg text-gray-700 mb-md-0">
                            {blog ? blog.user.username : ''} {blog ? blog.updateTime : ''}{/* <a>编辑</a> <a>删除</a> */}
                        </p>
                    </div>
                    {/*
                    <div className="col-auto">
                        <a href="#!" className="btn btn-primary-subtle">
                        踩一踩
                        </a>
                    </div>
                    */}
                </div>
                <div className="row">
                    <div className="col-12">
                        <hr className="my-6 my-md-8"/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <p className="fs-lg text-gray-800 mb-6 mb-md-8" dangerouslySetInnerHTML={{ __html: blog ? blog.content : ''}}/>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default BlogContent;