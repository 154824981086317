export const ALERT_TYPE = {
    PRIMARY: 'primary',
    SECONDARY: 'secondary',
    SUCCESS: 'success',
    DANGER: 'danger',
    WARNING: 'warning',
    INFO: 'info',
    LIGHT: 'light',
    DARK: 'dark',
}

export const ALERT_MESSAGE = {
    FAILED: '操作失败。',
    VERIFICATION_CODE_SENT: '验证码已发送。',
    LOGIN_SUCCESS: '登录成功！',
    REGISTER_SUCCESS: '注册成功！',
    LOGOUT_SUCCESS: '注销成功！',
    LOGIN_FIRST: '请先登录。',
    BLOG_POST_SUCCESS: '博客保存成功！',
}

export const SYSTEM_NUMBER = {
    ONE_SECOND_MS: 1000,
    ALERT_DURATION_MS: 3000,
    VERIFICATION_CODE_GAP_SECOND: 60,
    EMAIL_MAX_LENGTH: 60,
    VERIFICATION_CODE_MAX_LENGTH: 6,
    USER_NAME_MAX_LENGTH: 60,
    PASSWORD_MAX_LENGTH: 60,
}