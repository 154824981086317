import React, { useEffect, useRef } from 'react';
import Quill from 'quill';

const QuillEditor = ({ options, onContentChange }) => {
    const rendererRef = useRef(null);
    const quillRef = useRef(null);

    useEffect(() => {
        if (rendererRef.current && !quillRef.current) {
            const defaultOptions = {
                modules: {
                    toolbar: [
                        ['bold', 'italic'],
                        ['link', 'blockquote', 'code', 'image'],
                        [
                            {
                                list: 'ordered',
                            },
                            {
                                list: 'bullet',
                            },
                        ],
                    ],
                },
                theme: 'snow',
            };

            const quillOptions = {
                ...defaultOptions,
                ...options,
            };

            quillRef.current = new Quill(rendererRef.current, quillOptions);

            quillRef.current.on('text-change', () => {
                const text = quillRef.current.root.innerHTML;
                if (onContentChange) {
                    onContentChange(text);
                }
            });

            return () => {
                quillRef.current.off('text-change');
                quillRef.current = null;
            };
        }
    }, []);

    return <div ref={rendererRef}></div>;
};

export default QuillEditor;
