import {Link, useNavigate} from "react-router-dom";
import {ALERT_TYPE, ALERT_MESSAGE} from "../../utils/util/Constants";
import {useLoading} from "../../utils/loading/LoadingContext";
import {useEffect, useState} from "react";
import {useAuth} from "../../utils/auth/AuthContext";
import {useAlert} from "../../utils/alert/AlertContext";

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const { setIsLoading } = useLoading();
    const navigate = useNavigate();
    const { token, login } = useAuth();
    const { triggerAlert } = useAlert();

    useEffect(() => {
        if (token) {
            navigate('/');
        }
    })

    const handleLogin = async () => {
        try {
            setIsLoading(true);

            const loginData = {
                email: email,
                password: password,
            };

            const response = await fetch('/api/auth/login', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(loginData),
            });

            if (response.ok) {
                const data = await response.json();
                login(data.token);
                triggerAlert(ALERT_MESSAGE.LOGIN_SUCCESS, ALERT_TYPE.SUCCESS);
                navigate('/');
            } else {
                triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
            }
        } catch (error) {
            triggerAlert(ALERT_MESSAGE.FAILED, ALERT_TYPE.DANGER);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <section>
            <div className="container d-flex flex-column">
                <div className="row align-items-center justify-content-center gx-0 min-vh-100">
                    <div className="col-12 col-md-6 col-lg-4 py-8 py-md-11">
                        <h1 className="mb-0 fw-bold">
                            登录
                        </h1>
                        <p className="mb-6 text-body-secondary">
                            犹言望日.
                        </p>
                        <form className="mb-6">
                            <div className="form-group">
                                <label className="form-label" htmlFor="email">
                                    Email
                                </label>
                                <input type="email" className="form-control" id="email" placeholder="Email"
                                       value={email} onChange={(e) => setEmail(e.target.value)}/>
                            </div>
                            <div className="form-group mb-5">
                                <label className="form-label" htmlFor="password">
                                    密码
                                </label>
                                <input type="password" className="form-control" id="password" placeholder="Password"
                                       value={password} onChange={(e) => setPassword(e.target.value)}/>
                            </div>
                            <button className="btn w-100 btn-primary" type="button" onClick={() => handleLogin()}>
                                登录
                            </button>
                        </form>
                        <p className="mb-0 fs-sm text-body-secondary">
                            没有账户？ <Link to="/resigter">注册</Link>.<br/><Link to="/">返回</Link>
                        </p>
                    </div>
                    <div className="col-lg-7 offset-lg-1 align-self-stretch d-none d-lg-block">
                        <div className="h-100 w-cover bg-cover"
                             style={{backgroundImage: `url(${process.env.PUBLIC_URL}/img/background_login.jpg)`}}></div>
                        <div className="shape shape-start shape-fluid-y text-white">
                            <svg viewBox="0 0 100 1544" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0h100v386l-50 772v386H0V0z" fill="currentColor"/>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Login;